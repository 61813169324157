import Vue from 'vue'
import Router from 'vue-router'
import Layout from "@/layout/index.vue"

Vue.use(Router)

export const constantRoutes = [
    {
        path: '/login',
        name: "login",
        component: () => import('@/views/login/index'),
    },
    {
        path: '/register',
        name: "register",
        component: () => import('@/views/login/register.vue'),
    },
    {
        path: '/reset',
        name: "reset",
        component: () => import('@/views/login/reset.vue'),
    },
    {
        path: '/thirdpartyLogin',
        name: "thirdpartyLogin",
        component: () => import('@/views/login/thirdpartyLogin.vue'),
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
    },
    {
        path: '/',
        component: Layout,
        // redirect: '/home',
        children: [
            //首页
            {
                path: 'home',
                name: 'home',
                component: () => import('@/views/home'),
                meta: { auth: ["M1"] }
            },
            //知识库
            {
                path: "knowledgeManage",
                name: "knowledgeManage",
                // redirect: "/knowledgeManage/knowledge",
                component: () => import('@/views/robotKnowledge/index.vue'),
                meta: { auth: ["M2"] },
                children: [
                    {
                        path: "knowledge",
                        name: "knowledge",
                        component: () => import('@/views/robotKnowledge/knowledge'),
                        meta: { auth: ["M2", "M8", "M23"] },
                    },
                    {
                        path: "dialogue",
                        name: "dialogue",
                        component: () => import('@/views/robotKnowledge/dialogue'),
                        meta: { auth: ["M2", "M8", "M52"] },
                    },
                    {
                        path: "dialogueProcess/:id",
                        name: "dialogueProcess",
                        hidden: true,
                        component: () => import('@/views/robotKnowledge/dialogue/dialogueProcess'),
                        meta: { auth: ["M2", "M8", "M52"] },
                    },
                    //知识库中转站
                    {
                        path: "knowledgeTransfer",
                        name: "knowledgeTransfer",
                        component: () => import('@/views/robotKnowledge/knowledgeTransfer'),
                        meta: { auth: ["M2", "M8", "M24"] },
                    },
                    //回收站
                    {
                        path: "recycleBin",
                        name: "recycleBin",
                        component: () => import('@/views/robotKnowledge/recycleBin'),
                        meta: { auth: ["M2", "M8", "M25"] },
                    },
                    //操作日志
                    {
                        path: "operationLog",
                        name: "operationLog",
                        component: () => import('@/views/robotKnowledge/operationLog'),
                        meta: { auth: ["M2", "M8", "M26"] },
                    },
                    //GPT语言素材
                    {
                        path: "gptMaterial",
                        name: "gptMaterial",
                        component: () => import('@/views/robotKnowledge/gptMaterial'),
                        meta: { auth: ["M2", "M8", "M23"] },
                    },
                    //词库
                    {
                        path: "thesaurus",
                        name: "thesaurus",
                        component: () => import('@/views/robotKnowledge/thesaurus'),
                        meta: { auth: ["M2", "M9", "M27"] },
                    },
                    //素材管理
                    {
                        path: "material",
                        name: "material",
                        component: () => import('@/views/robotKnowledge/material'),
                        meta: { auth: ["M2", "M10", "M28"] },
                    },
                    //知识集
                    {
                        path: "knowledgeSet",
                        name: "knowledgeSet",
                        component: () => import('@/views/robotKnowledge/knowledgeSet'),
                        meta: { auth: ["M2", "M11", "M29"] },
                    },
                    //智能学习-未知问题
                    {
                        path: "unknownProblem",
                        name: "unknownProblem",
                        component: () => import('@/views/robotKnowledge/unknownProblem'),
                        meta: { auth: ["M2", "M12", "M30"] },
                    },
                    //智能学习-智能训练
                    {
                        path: "intelligentTraining",
                        name: "intelligentTraining",
                        component: () => import('@/views/robotKnowledge/intelligentTraining'),
                        meta: { auth: ["M2", "M12", "M31"] },
                    },
                    //自定义标签
                    {
                        path: "customLabel",
                        name: "customLabel",
                        component: () => import('@/views/robotKnowledge/customLabel'),
                        meta: { auth: ["M2", "M13", "M32"] },
                    },
                ]
            },
            //渠道管理
            {
                path: "channelManage",
                name: "channelManage",
                // redirect: "/channelManage/api",
                component: () => import('@/views/robotChannel/index.vue'),
                meta: { auth: ["M3"] },
                children: [
                    //网页渠道
                    {
                        path: "web",
                        name: "web",
                        component: () => import('@/views/robotChannel/channel.vue')
                    },
                    //微信渠道
                    {
                        path: "weixin",
                        name: "weixin",
                        component: () => import('@/views/robotChannel/channel.vue')
                    },
                    //微信小程序渠道
                    {
                        path: "wxapp",
                        name: "wxapp",
                        component: () => import('@/views/robotChannel/channel.vue')
                    },
                    //App渠道
                    {
                        path: "app",
                        name: "app",
                        component: () => import('@/views/robotChannel/channel.vue')
                    },
                    //字节小程序渠道
                    {
                        path: "zjapp",
                        name: "zjapp",
                        component: () => import('@/views/robotChannel/channel.vue')
                    },
                    //ivr渠道
                    {
                        path: "ivr",
                        name: "ivr",
                        component: () => import('@/views/robotChannel/channel.vue')
                    },
                    //API渠道
                    {
                        path: "api",
                        name: "api",
                        component: () => import('@/views/robotChannel/channel.vue'),
                        meta: { auth: ["M3", "M14", "M39"] },
                    },
                    //默认窗口配置
                    {
                        path: "defaultConfig",
                        name: "defaultConfig",
                        component: () => import('@/views/robotChannel/defaultConfig.vue'),
                        meta: { auth: ["M3", "M15", "M40"] },
                    },
                ]
            },
            //报表统计
            {
                path: "robotReport",
                name: "robotReport",
                // redirect: "/robotReport/operationData",
                component: () => import('@/views/robotReport/index.vue'),
                meta: { auth: ["M4"] },
                children: [
                    //运营关键数据
                    {
                        path: "operationData",
                        name: "operationData",
                        component: () => import('@/views/robotReport/operationData/index.vue'),
                        meta: { auth: ["M4", "M16", "M41"] },
                    },
                    //聊天日志数据
                    {
                        path: "chatLog",
                        name: "chatLog",
                        component: () => import('@/views/robotReport/chatLog/index.vue'),
                        meta: { auth: ["M4", "M16", "M42"] },
                    },
                    //来访数据统计
                    {
                        path: "visitStatis",
                        name: "visitStatis",
                        component: () => import('@/views/robotReport/visitStatis/index.vue'),
                        meta: { auth: ["M4", "M17", "M43"] },
                    },
                    //知识库数据统计
                    {
                        path: "knowledgeStatis",
                        name: "knowledgeStatis",
                        component: () => import('@/views/robotReport/knowledgeStatis/index.vue'),
                        meta: { auth: ["M4", "M18", "M44"] },
                    },
                    //热点问题统计
                    {
                        path: "hotIssuesStatis",
                        name: "hotIssuesStatis",
                        component: () => import('@/views/robotReport/hotIssuesStatis/index.vue'),
                        meta: { auth: ["M4", "M18", "M45"] },
                    },
                    //问题数据统计
                    {
                        path: "problemData",
                        name: "problemData",
                        component: () => import('@/views/robotReport/problemData/index.vue'),
                        meta: { auth: ["M4", "M19", "M46"] },
                    },
                    //满意度统计
                    {
                        path: "satisfyStatistics",
                        name: "satisfyStatistics",
                        component: () => import('@/views/robotReport/satisfyStatistics/index.vue'),
                        meta: { auth: ["M4", "M19", "M47"] },
                    },
                    //转人工分析
                    {
                        path: "turnAgentAnalyze",
                        name: "turnAgentAnalyze",
                        component: () => import('@/views/robotReport/turnAgentAnalyze/index.vue'),
                        meta: { auth: ["M4", "M19", "M48"] },
                    },
                ]
            },
            //系统配置
            {
                path: "systemConfig",
                name: "systemConfig",
                // redirect: "/systemConfig/enterpriseInfo",
                component: () => import('@/views/systemConfig/index.vue'),
                meta: { auth: ["M5"] },
                children: [
                    //企业信息
                    {
                        path: "enterpriseInfo",
                        name: "enterpriseInfo",
                        component: () => import('@/views/systemConfig/enterpriseInfo/index.vue'),
                        meta: { auth: ["M5", "M20"] },
                    },
                    //账号管理
                    {
                        path: "accountManage",
                        name: "accountManage",
                        component: () => import('@/views/systemConfig/accountManage/index.vue'),
                        meta: { auth: ["M5", "M21"] },
                    },
                    //操作日志
                    {
                        path: "operationLog",
                        name: "accountOperationLog",
                        component: () => import('@/views/systemConfig/operationLog/index.vue'),
                        meta: { auth: ["M5", "M22"] },
                    },
                ]
            }
        ]
    },
    { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
    mode: 'hash',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

//解决重复点击路由报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
