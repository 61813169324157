<template>
    <section class="app-main">
        <!-- <keep-alive> -->
        <router-view />
        <!-- </keep-alive> -->
    </section>
</template>

<script>
export default {
    name: "AppMain"
};
</script>

<style scoped>
.app-main {
    padding: 0 20px 20px 20px;
    height: calc(100% - 80px);
    width: 100%;
    position: relative;
    overflow: hidden;
    background: #e7edf6;
}
</style>

<style lang="scss">
// .el-popup-parent--hidden {
//     .fixed-header {
//         padding-right: 15px;
//     }
// }
</style>
