<template>
    <div class="app-wrapper">
        <sidebar class="sidebar-container" />
        <div class="main-container">
            <div class="fixed-header">
                <navbar />
            </div>
            <app-main />
        </div>
    </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Sidebar from "./components/Sidebar.vue";
import AppMain from "./components/AppMain.vue";
import { generateOptions } from "@/utils/validate";
import { mapGetters } from "vuex";
import { getEnterpriseDetail } from "@/api/systemConfig";
import { getClassList } from "@/api/knowledge";
import { getSourceList, getDefault } from "@/api/channel.js";

export default {
    name: "Layout",
    components: {
        Navbar,
        Sidebar,
        AppMain,
    },
    methods: {
        tokenExpired() {
            this.$confirm('登录认证过期，请重新登录!', '提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                showClose: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('user/resetToken').then(() => {
                    location.reload();
                });
            });
        },
        //获取企业信息
        getEnterpriseDetail() {
            getEnterpriseDetail().then(resp => {
                if (resp.status == 0) {
                    this.$store.dispatch("user/setEnterpriseInfo", resp.data);
                }
            });
        },
        //获取渠道列表
        getSourceList() {
            var param = {
                pageNo: 1,
                pageSize: 999,
                type: "api"
            }
            getSourceList(param).then(resp => {
                if (resp.status == 0) {
                    this.$store.dispatch("knowledge/setArrayList", { sourceList: resp.data.list });
                }
            });
        },
        //获取默认窗口配置
        getDefault() {
            getDefault().then(resp => {
                if (resp.status == 0) {
                    this.$store.dispatch("knowledge/setArrayList", { defaultSourceConfig: resp.data });
                }
            });
        },
        //获取分类列表
        getClassList() {
            //知识分类
            getClassList("/v1/admin/class/getClassList").then((resp) => {
                if (resp.status == 0) {
                    var json = JSON.stringify(generateOptions(resp.data.list));
                    json = json.replace(/className/g, "label");
                    this.$store.dispatch("knowledge/setArrayList", { knowledgeList: JSON.parse(json) });
                }
            });
            //素材分类
            getClassList("/v1/admin/material/getClassList").then((resp) => {
                if (resp.status == 0) {
                    var json = JSON.stringify(generateOptions(resp.data.list));
                    json = json.replace(/className/g, "label");
                    this.$store.dispatch("knowledge/setArrayList", { materialList: JSON.parse(json) });
                }
            });
        }
    },
    created() {
        this.getEnterpriseDetail();
        this.getClassList();
        this.getSourceList();
        this.getDefault();
    },
    mounted() {
        if (this.tokenIsExpired == 1) {
            this.tokenExpired();
        }
        this.$eventBus.$on('updateSource', () => {
            this.getSourceList();
        });
        this.$eventBus.$on('updateDefaultSource', () => {
            this.getDefault();
        });
    },
    computed: {
        ...mapGetters(["tokenIsExpired"]),
    },
    watch: {
        tokenIsExpired(val) {
            if (val == 1) {
                this.tokenExpired();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.module.scss";

.app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    &.mobile.openSidebar {
        position: fixed;
        top: 0;
    }
}
.drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
}

.fixed-header,
.app-main {
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
}

.fixed-header {
    background: #7063e8;
}
.sidebar-container {
    transition: width 0.28s;
    width: #{$sideBarWidth} !important;
    background: linear-gradient(180deg, #7063e8, #504ce8);
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;
    padding-top: 40px;
}
.main-container {
    width: 100%;
    height: auto;
    transition: all 0.5s;
    margin-left: 0px;
    box-sizing: border-box;
    margin-left: #{$sideBarWidth};
    position: relative;
}
</style>
