import request from '@/utils/axios'

//渠道管理

//获取渠道列表
export function getSourceList(data) {
    return request({
        url: "/v1/admin/source/getSourceList",
        method: 'post',
        data: JSON.stringify(data)
    });
}

//编辑渠道名称
export function editBaseSource(data) {
    return request({
        url: "/v1/admin/source/editBaseSource",
        method: 'post',
        data: JSON.stringify(data)
    });
}

//新增渠道
export function addBaseSource(data) {
    return request({
        url: "/v1/admin/source/addBaseSource",
        method: 'post',
        data: JSON.stringify(data)
    });
}

//新增渠道
export function addSource(data) {
    return request({
        url: "/v1/admin/source/addSource",
        method: 'post',
        data: JSON.stringify(data)
    });
}

//编辑渠道
export function editSource(data) {
    return request({
        url: "/v1/admin/source/editSource",
        method: 'post',
        data: JSON.stringify(data)
    });
}

//删除渠道
export function delSource(data) {
    return request({
        url: "/v1/admin/source/delSource",
        method: 'post',
        data: JSON.stringify(data)
    });
}

//获取默认窗口配置
export function getDefault() {
    return request({
        url: "/v1/admin/source/getDefault",
        method: 'post',
        data: JSON.stringify({})
    });
}

//编辑默认窗口配置
export function editDefault(data) {
    return request({
        url: "/v1/admin/source/editDefault",
        method: 'post',
        data: JSON.stringify(data)
    });
}

//----------------机器人测试窗口-------------
var frontUrl = "";
//需调用本地服务接口可取消以下注释
// frontUrl = process.env.NODE_ENV == "development" ? process.env.VUE_APP_BASE_API + ":609" : process.env.VUE_APP_BASE_API;
//获取历史聊天记录
export function getChatHistory(data) {
    return request({
        url: frontUrl + "/v1_2/front/chatHistory",
        method: 'post',
        data: JSON.stringify(data)
    });
}

//机器人交互
export function apiChat(data) {
    return request({
        url: frontUrl + "/v1_2/front/apichat",
        method: 'post',
        data: JSON.stringify(data)
    });
}

//满意度评价
export function satisfaction(data) {
    return request({
        url: frontUrl + "/v1_2/front/satisfaction",
        method: 'post',
        data: JSON.stringify(data)
    });
}
