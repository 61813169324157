import router from './router';
import store from './store';
import { getToken } from '@/utils/auth';
import { Message } from 'ant-design-vue';
const whiteList = ['/login', '/register', '/reset', '/thirdpartyLogin'];

router.beforeEach(async (to, from, next) => {
    const hasToken = getToken();
    if (hasToken) {
        var whiteLists = [...whiteList, "/"];
        var auth = to.meta.auth || [];
        var menuAuthCodes = store.getters.menuAuthCodes;
        if (auth.length > 0 && whiteLists.indexOf(to.path) < 0) {
            var isPass = auth.every((item) => menuAuthCodes.includes(item));
            if (isPass) {
                return next();
            } else {
                Message.warning("您没有访问该页面的权限!");
            }
        } else {
            next();
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next();
        } else {
            next(`/login`);
        }
    }
});

router.afterEach(() => {
});
