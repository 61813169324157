import axios from "axios";
import store from '@/store';

const baseUrl = process.env.VUE_APP_MRG_API;

const service = axios.create();

service.defaults.baseURL = baseUrl;
service.defaults.headers['Content-Type'] = 'application/json';

// 添加请求拦截器
service.interceptors.request.use(function (config) {
    if (store.getters.token) {
        config.headers['X-TOKEN'] = store.getters.token;
    }
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        config.transformRequest = data => {
            let ret = '';
            for (let it in data) {
                if (typeof data[it] == 'object') {
                    ret += encodeURIComponent(it) + '=' + JSON.stringify(data[it]) + '&'
                } else {
                    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
                }
            }
            ret = ret.substr(0, ret.length - 1);
            return ret;
        }
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    var response = error.response;
    //token过期、失效
    if (response.status == 401) {
        store.dispatch('user/setTokenExpired', 1);
    }
    return Promise.reject(error);
});

export default service