import { login } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';

const getDefaultState = () => {
    return {
        token: getToken(),
        tokenIsExpired: JSON.parse(localStorage.getItem("tokenIsExpired")) || 0, //token是否过期
        enterpriseInfo: JSON.parse(localStorage.getItem("enterpriseInfo")) || {},
        sessionInfo: JSON.parse(localStorage.getItem("sessionInfo")) || {}, //测试窗口会话ID,每半小时更新一次
        userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}, //登录账号信息
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_TOKENEXPIRED: (state, isExpired) => {
        state.tokenIsExpired = isExpired;
    },
    SET_ENTERPRISEINFO: (state, data) => {
        state.enterpriseInfo = Object.assign({}, data);
    },
    SET_SESSIONINFO: (state, sessionInfo) => {
        state.sessionInfo = sessionInfo;
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo;
    },
}

const actions = {
    // user login
    login({ commit, dispatch }, loginForm) {
        return new Promise((resolve, reject) => {
            login(loginForm).then(resp => {
                if (resp.status == 0) {
                    const { data } = resp
                    commit('SET_TOKEN', data.token);
                    dispatch("setTokenExpired", 0);
                    setToken(data.token);
                    resolve(resp);
                } else {
                    reject(resp);
                }
            }).catch(error => {
                reject(error);
            });
        });
    },

    //第三方通过token登录
    thirdpartyLogin({ commit, dispatch }, token) {
        return new Promise((resolve, reject) => {
            commit('SET_TOKEN', token);
            dispatch("setTokenExpired", 0);
            setToken(token);
            resolve();
        });
    },

    setUserInfo({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            var info = { ...userInfo.userInfo };
            info.menuAuthCodes = userInfo.menuAuthCodes;
            commit('SET_USERINFO', info);
            localStorage.setItem("userInfo", JSON.stringify(info));
            resolve();
        });
    },

    // set enterpriseInfo
    setEnterpriseInfo({ commit }, data) {
        commit('SET_ENTERPRISEINFO', data);
        localStorage.setItem("enterpriseInfo", JSON.stringify(data));
    },

    // user logout
    logout({ commit }) {
        return new Promise((resolve) => {
            removeToken();
            resetRouter();
            commit('RESET_STATE');
            resolve();
        })
    },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken();
            commit('RESET_STATE');
            resolve();
        })
    },

    setTokenExpired({ commit }, isExpired) {
        commit('SET_TOKENEXPIRED', isExpired);
        localStorage.setItem("tokenIsExpired", isExpired);
    },

    setSessionInfo({ commit }, sessionId) {
        if (sessionId) {
            var sessionInfo = {
                expiresAt: (new Date().getTime()) + (1000 * 60 * 30),
                sessionId
            }
            commit('SET_SESSIONINFO', sessionInfo);
            localStorage.setItem("sessionInfo", JSON.stringify(sessionInfo));
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

