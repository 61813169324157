<template>
    <el-drawer :visible.sync="visible" :modal="false" :size="780" custom-class="robotDrawer" :show-close="false" append-to-body
               @close="close" @open="open">
        <div slot="title" style="display:flex">
            <span class="iconfont icon-guanbi2 close" @click="close"></span>
            <span class="title">机器人测试窗口</span>
        </div>
        <div class="testRobot robotChatLog">
            <el-select v-model="sourceId" placeholder="请选择渠道" size="small" style="width: 100%" @change="sourceChange">
                <el-option v-for="item in sourceList" :key="item.id" :label="item.sourceName" :value="item.id"></el-option>
            </el-select>
            <div :class="['chatDataList',{'hasCapsules' : guideCapsules.length > 0}]" id="chatDataList">
                <div class="chatDataItem" v-for="(item,index) in chatListData" :key="index">
                    <p class="time">{{item.questionCreateTime}}</p>
                    <!-- 访客信息 -->
                    <div class="visitor-msg" v-if="item.question">
                        <div class="msg">{{item.question}}</div>
                        <img src="../../assets/images/head.png" alt="">
                    </div>
                    <!-- 机器人回复 -->
                    <div v-if="item.robotReply">
                        <div class="robot-msg" v-for="(inner,i) in item.robotReply" :key="i">
                            <img :src="robotAvatarUrl" v-if="robotAvatarUrl" class="robotAvatarUrl" />
                            <span v-else class="iconfont icon-fangketouxiang icontx"></span>
                            <div class="msg-content">
                                <div class="msg" v-if="['text','unknown'].includes(inner.msgtype)" v-html="inner.text.content"></div>
                                <div class="msg notbg" v-else-if="inner.msgtype == 'image'" v-viewer>
                                    <img :src="inner.image.url" :alt="inner.image.name">
                                </div>
                                <div class="msg notbg" v-else-if="inner.msgtype == 'voice'">
                                    <audio :src="inner.voice.url" controls></audio>
                                </div>
                                <div class="msg notbg" v-else-if="inner.msgtype == 'video'">
                                    <video :src="inner.video.url" controls></video>
                                </div>
                                <div class="msg" v-else-if="inner.msgtype == 'file'">
                                    <el-link :href="inner.file.url" target="_blank" type="primary">{{inner.file.name}}</el-link>
                                </div>
                                <div class="msg notbg graphics" v-else-if="inner.msgtype == 'news'">
                                    <div v-for="(atc, num) in inner.news.articles" class="graphicsItem" @click="openLink(atc.url)">
                                        <img :src="atc.picurl" :alt="atc.title">
                                        <p class="title">{{atc.title}}</p>
                                    </div>
                                </div>
                                <div class="msg" v-else-if="inner.msgtype == 'richText'">
                                    <div v-html="inner.richText.content" v-viewer></div>
                                </div>
                                <div class="msg robotRec" v-else-if="inner.msgtype == 'guide'">
                                    <p class="preDesc">{{inner.guide.beforeWord}}</p>
                                    <div v-if="isWeChat" class="wxQuestion">
                                        <p v-for="(glist, w) in inner.guide.list" :key="w" :title="glist.question">
                                            {{ (w+1) + "、" + glist.question }}
                                        </p>
                                    </div>
                                    <div class="recQuestion" v-for="(glist, j) in inner.guide.list" :key="j" v-else
                                         @click="sendQuestionMsg(item.isHistory,glist.question)" :title="glist.question">
                                        {{glist.question}}
                                    </div>
                                    <p class="aftDesc" v-if="inner.guide.afterWord">{{inner.guide.afterWord}}</p>
                                </div>

                                <div class="msg robotRec" v-else-if="inner.msgtype == 'options'">
                                    <p class="preDesc">{{inner.options.beforeWord}}</p>
                                    <div v-if="isWeChat" class="wxQuestion">
                                        <p v-for="(glist, w) in inner.options.list" :key="w" :title="glist.question">
                                            {{ (w+1) + "、" + glist.question }}
                                        </p>
                                    </div>
                                    <div class="recQuestion" v-for="(glist, j) in inner.options.list" :key="j" v-else
                                         @click="sendQuestionMsg(item.isHistory,glist.option)" :title="glist.option">
                                        {{glist.option}}
                                    </div>
                                    <p class="aftDesc" v-if="inner.options.afterWord">{{inner.options.afterWord}}</p>
                                </div>

                                <div class="msgRec robotRec" v-if="inner.recommendQuestion">
                                    <p class="preDesc">{{inner.recommendQuestion.beforeWord}}</p>
                                    <div v-if="isWeChat" class="wxQuestion">
                                        <p v-for="(glist, w) in inner.recommendQuestion.list" :key="w" :title="glist.question">
                                            {{ (w+1) + "、" + glist.question }}
                                        </p>
                                    </div>
                                    <div class="recQuestion" v-for="(glist, j) in inner.recommendQuestion.list" :key="j" v-else
                                         @click="sendQuestionMsg(item.isHistory,glist.question)" :title="glist.question">
                                        {{glist.question}}
                                    </div>
                                    <p class="aftDesc" v-if="inner.recommendQuestion.afterWord">{{inner.recommendQuestion.afterWord}}</p>
                                </div>

                                <div class="satisfy" v-if="inner.satisfaction && !item.isHistory">
                                    <p>{{inner.satisfaction.beforeWord}}</p>
                                    <p class="satisfyBtn">
                                        <el-tooltip effect="light" content="满意" placement="top" :enterable="false">
                                            <span class="iconfont icon-manyi" @click="satisfaction(inner,1,$event)"></span>
                                        </el-tooltip>
                                        <el-tooltip effect="light" content="不满意" placement="top" :enterable="false">
                                            <span class="iconfont icon-bumanyi" @click="satisfaction(inner,0,$event)"></span>
                                        </el-tooltip>
                                    </p>
                                </div>
                            </div>
                            <el-button type="primary" size="small" plain style="margin: 5px 0 0 15px"
                                       v-if="item.seatType == 'seatPrompt' && !item.isHistory">
                                转人工客服
                            </el-button>
                        </div>
                    </div>
                    <p class="time" v-if="item.seatType == 'seatAuto'">自动转人工</p>
                    <!-- 引导卡片 -->
                    <div v-if="guideCards.length > 0 && item.question == ''" class="guideCardList">
                        <div class="guideCard" v-for="(card,i) in guideCards" :key="i" @click="formatClick(card, item.isHistory)">
                            <img :src="card.coverUrl">
                        </div>
                    </div>
                </div>
            </div>
            <div class="capsulesList" v-if="guideCapsules.length > 0">
                <div v-for="(item,i) in guideCapsules" :key="i" class="capsulesItem" @click="formatClick(item)">
                    <img v-if="item.coverUrl" :src="item.coverUrl" class="capImg" />
                    <span>{{item.name}}</span>
                </div>
            </div>
            <div class="footer">
                <el-input v-model="msg" placeholder="请输入内容" @keyup.enter.native="sendMsg()"></el-input>
                <span class="iconfont icon-fasong" id="sendMsg" @click="sendMsg()"></span>
            </div>
        </div>
    </el-drawer>
</template>

<script>
import { getChatHistory, apiChat, satisfaction } from "@/api/channel.js";
import { mapGetters } from "vuex";
export default {
    name: "robotDrawer",
    props: {
        drawerVisible: {
            type: Boolean,
            default: () => {
                return false;
            },
        }
    },
    data() {
        return {
            visible: this.drawerVisible,
            msg: "",
            sourceId: "",
            chatListData: [],
        }
    },
    methods: {
        sendQuestionMsg(isHistory, question) {
            if (!isHistory) {
                this.sendMsg(question);
            }
        },
        //triggerWelCome: 触发欢迎语
        sendMsg(question = "", triggerWelCome = false, event) {
            let index = 0;
            var sessionId = this.sessionInfo.sessionId ? this.sessionInfo.sessionId : "";
            if (!this.sourceId) {
                return this.$antMessage.warning("请先选择渠道");
            }
            if (this.msg || question || triggerWelCome) {
                if (!triggerWelCome) {
                    var data = {
                        questionCreateTime: new Date().format("yyyy-MM-dd HH:mm:ss"),
                        question: this.msg || question,
                        robotReply: []
                    }
                    this.chatListData.push(data);
                    index = this.chatListData.length - 1;
                    this.scrollToBottom();
                }
                var param = {
                    question: question ? question : this.msg,
                    sourceId: this.sourceId,
                    clientId: this.userInfo.id,
                    sessionId: ''
                }
                this.msg = "";
                apiChat(param).then(resp => {
                    if (resp.status == 0) {
                        this.$store.dispatch('user/setSessionInfo', sessionId);
                        if (triggerWelCome) {
                            resp.data.guideCards = this.guideCards;
                            this.chatListData.push(resp.data);
                        } else {
                            this.chatListData.splice(index, 1, resp.data);
                        }
                        this.scrollToBottom();
                    } else {
                        this.$antMessage.warning(resp.message);
                    }
                });
            }
        },
        getChatHistory(cb) {
            var param = {
                beforeTime: "",
                sourceId: this.sourceId,
                clientId: this.userInfo.id
            };
            getChatHistory(param).then(resp => {
                if (resp.status == 0) {
                    var list = resp.data || [];
                    list.reverse();
                    list.forEach(item => { item.isHistory = true });
                    this.chatListData = list;
                    this.scrollToBottom();
                    cb && cb();
                } else {
                    this.$antMessage.warning(resp.message);
                }
            });
        },
        scrollToBottom() {
            this.$nextTick(() => {
                var container = document.getElementById("chatDataList");
                setTimeout(function () {
                    container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
                }, 300);
            })
        },
        open() {
            this.scrollToBottom();
        },
        close() {
            this.$emit("update:drawerVisible", false);
        },
        openLink(url) {
            if (url) {
                window.open(url);
            }
        },
        sourceChange() {
            this.chatListData = [];
            this.getChatHistory(() => {
                //发送空消息触发欢迎语
                this.sendMsg("", true);
            });
        },
        satisfaction(data, type, event) {
            var param = {
                answerId: data.answerId,
                uniqueId: data.uniqueId,
                isSatisfied: type
            }
            satisfaction(param).then(resp => {
                if (resp.status == 0) {
                    this.$antMessage.success(resp.message);
                    $(event.target).parent().css("pointer-events", "none");
                    $(event.target).addClass("active");
                } else {
                    this.$antMessage.warning(resp.message);
                }
            });
        },
        formatClick(data, isHistory = false) {
            if (data.type == 1 || data.type == 3) {
                this.sendQuestionMsg(isHistory, data.question)
            } else if (data.type == 2) {
                this.openLink(data.link);
            }
        },
    },
    computed: {
        ...mapGetters(["sourceList", "defaultSourceConfig", "sessionInfo", "userInfo"]),
        robotAvatarUrl() {
            var avatarUrl = '';
            var index = this.sourceList.findIndex(item => item.id == this.sourceId);
            if (index > -1) {
                if (this.sourceList[index].customStatus == 1) {
                    avatarUrl = this.sourceList[index].robotAvatarUrl;
                } else {
                    avatarUrl = this.defaultSourceConfig.robotAvatarUrl ? this.defaultSourceConfig.robotAvatarUrl : "";
                }
            }
            return avatarUrl;
        },
        //引导卡片
        guideCards() {
            var guideCards = [];
            var index = this.sourceList.findIndex(item => item.id == this.sourceId);
            if (index > -1) {
                if (this.sourceList[index].customStatus == 1) {
                    guideCards = this.sourceList[index].guideCards;
                } else {
                    guideCards = this.defaultSourceConfig.guideCards;
                }
            }
            return guideCards;
        },
        //引导胶囊
        guideCapsules() {
            var guideCapsules = [];
            var index = this.sourceList.findIndex(item => item.id == this.sourceId);
            if (index > -1) {
                if (this.sourceList[index].customStatus == 1) {
                    guideCapsules = this.sourceList[index].guideCapsules;
                } else {
                    guideCapsules = this.defaultSourceConfig.guideCapsules;
                }
            }
            return guideCapsules;
        },
        isWeChat() {
            var index = this.sourceList.findIndex(item => item.id == this.sourceId);
            if (index > -1) {
                return this.sourceList[index].type == "weixin";
            }
            return false;
        }
    },
    watch: {
        drawerVisible(val) {
            this.visible = val;
        }
    }
}
</script>

<style lang="scss">
.robotDrawer {
    .el-drawer__body {
        .footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 60px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            box-shadow: 0px 3px 15px 0px rgba(102, 112, 123, 0.3);
            #sendMsg {
                display: inline-block;
                flex-shrink: 0;
                margin-left: 20px;
                color: #6b5ee2;
                font-size: 50px;
                cursor: pointer;
                height: 40px;
                line-height: 40px;
            }
            .el-input__inner {
                border: none !important;
            }
        }
    }
    .testRobot {
        padding: 20px;
        height: calc(100% - 60px);
        .chatDataList {
            margin-top: 20px;
            height: calc(100% - 50px);
        }
        .hasCapsules {
            height: calc(100% - 90px);
        }
        .guideCardList {
            margin: 0;
            padding-left: 50px;
            .guideCard {
                width: 120px;
                height: 150px;
                cursor: pointer;
                margin-right: 20px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    object-fit: cover;
                }
            }
        }
        .capsulesList,
        .guideCardList {
            margin-top: 10px;
            display: flex;
            flex-wrap: nowrap;
            overflow-y: hidden;
            overflow-x: scroll;
            .capsulesItem {
                display: flex;
                align-items: center;
                height: 34px;
                padding: 0 23px;
                color: #6b5ee2;
                border: 1px solid #6b5ee2;
                border-radius: 20px;
                margin-right: 20px;
                cursor: pointer;
                .capImg {
                    width: 20px;
                    margin-right: 5px;
                }
            }
        }
        .msg-content {
            position: relative;
        }
        .satisfy {
            margin-top: 10px;
            position: absolute;
            width: 250px;
            .satisfyBtn {
                .iconfont {
                    font-size: 24px;
                    margin-right: 25px;
                    cursor: pointer;
                }
                .iconfont.active {
                    color: #6b5ee2;
                }
            }
        }
    }
}
</style>