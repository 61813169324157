import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import { mapGetters } from "vuex";
import ElementUI from 'element-ui';
import { Select, TreeSelect, Message } from 'ant-design-vue';
import $ from 'jquery';
import animated from 'animate.css';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css/normalize.css';
import '@/assets/iconfont/iconfont.css';
import "@/assets/theme/index.css"; //修改主题色
import '@/styles/index.scss' // global css
import "@/utils/directives.js"; //自定义指令
import '@/permission';

//扩展date
Date.prototype.format = function (format) {
    var o =
    {
        "M+": this.getMonth() + 1, //month
        "d+": this.getDate(),    //day
        "H+": this.getHours(),   //hour
        "m+": this.getMinutes(), //minute
        "s+": this.getSeconds(), //second
        "q+": Math.floor((this.getMonth() + 3) / 3),  //quarter
        "S": this.getMilliseconds() //millisecond
    }
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(format))
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    return format;
}

Vue.config.productionTip = false;
Vue.prototype.axios = axios;
Vue.prototype.$antMessage = Message;
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$robotLoading = (text = "") => {
    let loading = ElementUI.Loading.service({
        lock: true,
        text: text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    return loading;
}
Vue.use(ElementUI);
Vue.use(TreeSelect).use(Select);
Vue.use(Viewer, {
    defaultOptions: {
        title: false,
        navbar: false,
        toolbar: { prev: false, next: false, zoomIn: 4, zoomOut: 4, oneToOne: 4, reset: 4, play: false, rotateLeft: 4, rotateRight: 4, flipHorizontal: 4, flipVertical: 4 },
    }
})

document.title = "灵燕";
window.jQuery = $;
window.$ = $;

Vue.mixin({
    computed: {
        ...mapGetters(["menuAuthCodes"]),
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
