import Vue from 'vue';

//表格高度自适应
Vue.directive('resizeHeight', {
    bind(el, binding) {
        var height = document.body.clientHeight - binding.value;
        window.onresize = function () {
            $(el).find(".el-table__body-wrapper").height(document.body.clientHeight - height - 50);
            el.style.height = document.body.clientHeight - height + "px";
        };
    }
})