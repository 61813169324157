const state = {
    knowledgeList: [],
    materialList: [],
    sourceList: [],
    defaultSourceConfig: {}
}

const mutations = {
    SET_ARRAYLIST(state, object) {
        for (let key in object) {
            state[key] = object[key];
        }
    },
}

const actions = {
    setArrayList({ commit }, list) {
        commit('SET_ARRAYLIST', list)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
