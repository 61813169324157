const getters = {
    knowledgeList: state => state.knowledge.knowledgeList,
    sourceList: state => state.knowledge.sourceList,
    materialList: state => state.knowledge.materialList,
    defaultSourceConfig: state => state.knowledge.defaultSourceConfig,
    token: state => state.user.token,
    tokenIsExpired: state => state.user.tokenIsExpired,
    enterpriseInfo: state => state.user.enterpriseInfo,
    sessionInfo: state => state.user.sessionInfo,
    userInfo: state => state.user.userInfo,
    menuAuthCodes: state => state.user.userInfo.menuAuthCodes,
}
export default getters
