import request from '@/utils/axios';

//获取人员列表
export function getUserList(data) {
    return request({
        url: "/v1/admin/public/getUserList",
        method: 'post',
        data: JSON.stringify(data)
    });
}

//导入
export function importFile(url, data) {
    return request({
        url: url,
        method: 'post',
        data: JSON.stringify(data)
    });
}

//知识库

//知识类目
//获取知识分类
export function getClassList(url) {
    return request({
        url: url,
        method: 'post',
        data: JSON.stringify({})
    });
}

//添加知识分类
export function addClass(url, data) {
    return request({
        url: url,
        method: 'post',
        data: JSON.stringify(data)
    });
}

//编辑知识分类
export function editClass(url, data) {
    return request({
        url: url,
        method: 'post',
        data: JSON.stringify(data)
    });
}

//删除知识分类
export function delClass(url, data) {
    return request({
        url: url,
        method: 'post',
        data: JSON.stringify(data)
    });
}

//分类排序
export function orderClass(url, data) {
    return request({
        url: url,
        method: 'post',
        data: JSON.stringify(data)
    });
}

//FAQ问题管理
//获取问题列表
export function getQueList(data) {
    return request({
        url: '/v1/admin/question/getQueList',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//获取已有答案列表
export function getAnsList(data) {
    return request({
        url: '/v1/admin/study/getAnswerList',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//添加问题
export function addQuestion(data) {
    return request({
        url: '/v1/admin/question/doAddQueAndAns',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//获取问题详情
export function findQuesById(data) {
    return request({
        url: '/v1/admin/question/findQuesById',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//编辑问题详情
export function editQuestion(data) {
    return request({
        url: '/v1/admin/question/editQuestion',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//删除问题
export function delQuestion(data) {
    return request({
        url: '/v1/admin/question/delQuestionByIds',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//修改生效状态
export function editQuestionStatus(data) {
    return request({
        url: "/v1/admin/question/editQuestionStatus",
        method: 'post',
        data: JSON.stringify(data)
    });
}

//批量修改问题
export function batchEditQuestion(url, data) {
    return request({
        url: url,
        method: 'post',
        data: JSON.stringify(data)
    });
}


//查询相似问题列表
export function findSimilarQuestion(data) {
    return request({
        url: '/v1/admin/question/findSimilarQuestion',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//添加相似问题
export function addSimilarQuestion(data) {
    return request({
        url: '/v1/admin/question/addSimilarQuestion',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//编辑相似问题
export function editSimilarQuestion(data) {
    return request({
        url: '/v1/admin/question/editSimilarQuestion',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//删除相似问题
export function delSimilarQuestion(data) {
    return request({
        url: '/v1/admin/question/delSimilarQuestion',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//智能推荐相似问法
export function genSim(data) {
    return request({
        url: '/v1/admin/question/genSim',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//批量添加相似问法
export function batchAddSimilarQue(data) {
    return request({
        url: '/v1/admin/question/addSimilarQuestionBatch',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//导出问答列表
export function exportQuestion(data) {
    return request({
        url: '/v1/admin/question/exportQuestion',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//知识集
//知识集列表
export function getKnowledgeSetList(data) {
    return request({
        url: '/v1/admin/question/getKnowledgeSetList',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//知识集新增
export function addKnowledgeSet(data) {
    return request({
        url: '/v1/admin/question/addKnowledgeSet',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//知识集编辑
export function editKnowledgeSet(data) {
    return request({
        url: '/v1/admin/question/editKnowledgeSet',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//知识集删除
export function delKnowledgeSet(data) {
    return request({
        url: '/v1/admin/question/delKnowledgeSet',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//自定义标签
//自定义标签分类列表
export function getLabelClassList() {
    return request({
        url: '/v1/admin/label/getClassList',
        method: 'post',
        data: JSON.stringify({})
    });
}

//自定义标签分类新增
export function addLabelClassList(data) {
    return request({
        url: '/v1/admin/label/addClass',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//自定义标签分类编辑
export function editLabelClassList(data) {
    return request({
        url: '/v1/admin/label/editClass',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//自定义标签分类删除
export function delLabelClassList(data) {
    return request({
        url: '/v1/admin/label/delClassById',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//自定义标签列表
export function getLabelList(data) {
    return request({
        url: '/v1/admin/label/getLabelList',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//自定义标签新增
export function addLabel(data) {
    return request({
        url: '/v1/admin/label/addLabel',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//自定义标签编辑
export function editLabel(data) {
    return request({
        url: '/v1/admin/label/editLabel',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//自定义标签删除
export function delLabel(data) {
    return request({
        url: '/v1/admin/label/delLabel',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//获取多轮会话列表
export function getDialogueList(data) {
    return request({
        url: '/v1/admin/dialogue/getDialogueList',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//多轮会话新增
export function addDialogue(data) {
    return request({
        url: '/v1/admin/dialogue/addDialogue',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//多轮会话编辑
export function editDialogue(data) {
    return request({
        url: '/v1/admin/dialogue/editDialogue',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//多轮会话删除
export function delDialogue(data) {
    return request({
        url: '/v1/admin/dialogue/delDialogueByIds',
        method: 'post',
        data: JSON.stringify(data)
    });
}


//获取词槽能力组件列表
export function getDialogueSlotAbilityList(data) {
    return request({
        url: '/v1/admin/dialogueSlotAbility/getDialogueSlotAbilityList',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//词槽能力组件新增
export function addDialogueSlotAbility(data) {
    return request({
        url: '/v1/admin/dialogueSlotAbility/addDialogueSlotAbility',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//词槽能力组件编辑
export function editDialogueSlotAbility(data) {
    return request({
        url: '/v1/admin/dialogueSlotAbility/editDialogueSlotAbility',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//词槽能力组件删除
export function delDialogueSlotAbility(data) {
    return request({
        url: '/v1/admin/dialogueSlotAbility/delDialogueSlotAbility',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//调试词槽能力组件
export function debugDialogueSlotAbility(data) {
    return request({
        url: '/v1/admin/dialogueSlotAbility/debugDialogueSlotAbility',
        method: 'post',
        data: JSON.stringify(data)
    });
}

