/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
    const valid_map = ['admin', 'editor']
    return valid_map.indexOf(str.trim()) >= 0
}

export function generateOptions(dataList) {
    var result = [];
    for (var i = 0; i < dataList.length; i++) {
        var param = dataList[i];
        param.key = param.id;
        if (param.parentId == "0") {
            var parent = param;
            if (getchilds(param.id, dataList).length > 0) {
                parent.children = getchilds(param.id, dataList);
            }
            result.push(parent);
        }
    }
    return result;
}

function getchilds(id, array) {
    let childs = [];
    for (var i = 0; i < array.length; i++) {
        var arr = array[i];
        if (arr.parentId == id) {
            childs.push(arr);
        }
    }
    for (var j = 0; j < childs.length; j++) {
        var child = childs[j];
        var childscopy = getchilds(child.id, array);
        if (childscopy.length > 0) {
            child.children = childscopy;
        }
    }
    return childs;
}


export function familyTree(arr1, id) {
    var temp = []
    var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
            var item = arr[i]
            if (item.id === id) {
                temp.unshift(item.id)
                forFn(arr1, item.parentId)
                break
            } else {
                if (item.children) {
                    forFn(item.children, id)
                }
            }
        }
    }
    forFn(arr1, id)
    return temp
}

//树形数组扁平化
export function flatTreeList(tree) {
    return tree.reduce((res, item) => {
        const { children, ...i } = item;
        return res.concat(i, children && children.length ? flatTreeList(children) : [])
    }, []);
}