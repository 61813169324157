import request from '@/utils/mgrAxios';

//企业信息
//获取企业信息
export function getEnterpriseDetail() {
    return request({
        url: "/mgr/enterprise/getDetail",
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//修改企业信息
export function updateEnterprise(data) {
    return request({
        url: "/mgr/enterprise/update",
        method: 'post',
        data: JSON.stringify(data),
    });
}

//发送（重置appSecret）短信验证码
export function sendSmsCaptcha(data) {
    return request({
        url: "/mgr/captcha/sendSmsCaptchaToSuperAdmin",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//验证（重置appSecret）短信验证码
export function verifySmsCaptcha(data) {
    return request({
        url: "/mgr/captcha/verifySmsCaptchaForSuperAdmin",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//重置appSecret
export function resetAppSecret(data) {
    return request({
        url: "/mgr/enterprise/resetAppSecret",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//--------------------------------------------------------------------

//账号管理

//获取账号列表
export function queryList(data) {
    return request({
        url: "/mgr/user/queryList",
        method: 'post',
        data: JSON.stringify(data),
    });
}

//添加账号
export function addUser(data) {
    return request({
        url: "/mgr/user/addUser",
        method: 'post',
        data: JSON.stringify(data),
    });
}

//编辑账号
export function updateUser(data) {
    return request({
        url: "/mgr/user/update",
        method: 'post',
        data: JSON.stringify(data),
    });
}

//删除账号
export function removeUser(data) {
    return request({
        url: "/mgr/user/remove",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//更新账号状态(启用或禁用)
export function updateUserStatus(data) {
    return request({
        url: "/mgr/user/updateUserStatus",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//重置账号密码
export function resetPassword(data) {
    return request({
        url: "/mgr/user/resetPassword",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//获取角色列表
export function getRolesList() {
    return request({
        url: "/mgr/roles/getRoles",
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//新增角色
export function addRole(data) {
    return request({
        url: "/mgr/roles/addRole",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//编辑角色
export function updateRole(data) {
    return request({
        url: "/mgr/roles/updateRole",
        method: 'post',
        data: JSON.stringify(data),
    });
}

//删除角色
export function removeRole(data) {
    return request({
        url: "/mgr/roles/removeRole",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//获取菜单权限树
export function getMenuAuthTree() {
    return request({
        url: "/mgr/menuAuth/queryAll2Tree",
        method: 'post',
        data: {},
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//获取角色菜单权限
export function getRoleMenuAuthIds(data) {
    return request({
        url: "/mgr/roles/getRoleMenuAuthIds",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//角色添加菜单权限
export function upsertRoleMenuAuth(data) {
    return request({
        url: "/mgr/roles/upsertRoleMenuAuth",
        method: 'post',
        data: JSON.stringify(data),
    });
}