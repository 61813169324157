<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
};
</script>

<style lang="scss">
#app {
    font-family: "Hiragino Sans GB", "Source Han Sans CN", "Microsoft YaHei", "微软雅黑", Arials, ans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
