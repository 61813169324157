<template>
    <div class="navbar">
        <div class="left-menu">
            <img v-if="enterpriseInfo.logoUrl" :src="enterpriseInfo.logoUrl" class="logo">
            <span v-else class="iconfont icon-zhishiku"></span>
            <span>{{enterpriseInfo.systemPlatformName ? enterpriseInfo.systemPlatformName : '机器人平台'}}</span>
        </div>
        <div class="right-menu">
            <el-button type="primary" class="mr20" @click="drawerVisible = true" v-if="menuAuthCodes.includes('M7')">
                测试机器人
            </el-button>
            <el-popover placement="bottom" width="300" trigger="click" popper-class="popover_userInfo">
                <div class="avatar-wrapper" slot="reference">
                    <img src="../../assets/images/head.png" class="user-avatar">
                    <span class="iconfont icon-xiala2"></span>
                </div>
                <div class="info mb20">
                    <div class="item">
                        <span class="label">坐席账号：</span>
                        <div :title="userInfo.account">{{userInfo.account}}</div>
                    </div>
                    <div class="item">
                        <span class="label">坐席名称：</span>
                        <div :title="userInfo.userName">{{userInfo.userName}}</div>
                    </div>
                    <div class="item">
                        <span class="label">手机：</span>
                        <div :title="userInfo.mobile">{{userInfo.mobile}}</div>
                    </div>
                    <div class="item">
                        <span class="label">邮箱：</span>
                        <div :title="userInfo.email">{{userInfo.email}}</div>
                    </div>
                    <div class="item">
                        <span class="label">角色：</span>
                        <div v-if="userInfo.roleNames">
                            <p v-for="(item,i) in userInfo.roleNames" :key="i">{{item}}</p>
                        </div>
                    </div>
                </div>
                <el-button type="primary" @click="logout" style="width: 100%">退出登录</el-button>
            </el-popover>
        </div>

        <!-- 测试机器人 -->
        <robot-drawer :drawerVisible.sync="drawerVisible"></robot-drawer>
    </div>
</template>

<script>
import robotDrawer from "./robotDrawer.vue";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            avatar: "",
            drawerVisible: false,
        };
    },
    computed: {
        ...mapGetters(["enterpriseInfo", "userInfo"]),
    },
    methods: {
        async logout() {
            await this.$store.dispatch("user/logout");
            this.$router.push(`/login`);
        },
    },
    components: {
        robotDrawer
    }
};
</script>

<style lang="scss">
.navbar {
    height: 80px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    background: #e7edf6;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    border-radius: 20px 0 0 0;
    .left-menu {
        display: flex;
        align-items: center;
        margin-left: 20px;
        font-size: 16px;
        color: #363c43;
        .iconfont {
            font-size: 20px;
            border-radius: 6px;
            background: #6b5ee2;
            color: #fff;
            margin-right: 15px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .logo {
            border-radius: 6px;
            margin-right: 15px;
            width: 40px;
            height: 40px;
        }
    }

    .right-menu {
        display: flex;
        align-items: center;
        height: 100%;
        flex-shrink: 0;
        padding-right: 20px;
        &:focus {
            outline: none;
        }
        .avatar-wrapper {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            .user-avatar {
                cursor: pointer;
                width: 40px;
                height: 40px;
                border-radius: 10px;
                margin-right: 12px;
            }
            .iconfont {
                color: #9c94f0;
            }
        }
    }
}
.popover_userInfo {
    padding: 20px !important;
    border-radius: 6px !important;
    box-shadow: 0px 3px 15px 0px rgba(102, 112, 123, 0.3) !important;
    border: none !important;
    .info {
        padding: 15px;
        box-shadow: 0px 0px 15px 0px #e1e9f1;
        .item {
            display: flex;
            color: #676e75;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px;
            }
            .label {
                width: 70px;
                text-align: right;
                flex-shrink: 0;
            }
            > div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
