import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import knowledge from './modules/knowledge';
import user from './modules/user';

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        knowledge,
        user
    },
    getters
})

export default store
