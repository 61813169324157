<template>
    <div>
        <el-tooltip effect="dark" v-for="(item,i) in menuList" :key="i" :content="item.title" placement="right" :enterable="false"
                    class="menuTool">
            <div :class="['sideBarItem',item.path==activeMenu?'activeItem':'']" @click="open(item.path,$event)">
                <span :class="['iconfont', item.icon]"></span>
            </div>
        </el-tooltip>
    </div>
</template>

<script>
import variables from "@/styles/variables.module.scss";
export default {
    name: "Sidebar",
    data() {
        return {
            currentPath: "/home",
            menuList: [
                // {
                //     title: "首页",
                //     icon: "icon-zhuye",
                //     path: "/home",
                //     menuCode: "M1",
                // },
                {
                    title: "知识库",
                    icon: "icon-zhishiku",
                    path: "/knowledgeManage",
                    menuCode: "M2",
                },
                {
                    title: "渠道管理",
                    icon: "icon-qudaoguanli",
                    path: "/channelManage",
                    menuCode: "M3",
                },
                {
                    title: "报表统计",
                    icon: "icon-baobiaotongji",
                    path: "/robotReport",
                    menuCode: "M4",
                },
                {
                    title: "系统配置",
                    icon: "icon-xitongpeizhi",
                    path: "/systemConfig",
                    menuCode: "M5",
                },
            ],
        };
    },
    created() {
        this.setMenuAuth();
    },
    methods: {
        setMenuAuth() {
            this.menuList = this.menuList.filter(item => this.menuAuthCodes.includes(item.menuCode));
            if (this.menuList.length > 0 && this.$route.path == "/") {
                this.$router.push(this.menuList[0].path);
            }
        },
        open(path, event) {
            var $el;
            if (event.target.nodeName == "SPAN") {
                $el = $(event.target).parents(".sideBarItem");
            } else {
                $el = $(event.target);
            }
            if (!$el.hasClass("activeItem")) {
                this.$router.push(path);
            }
        }
    },
    computed: {
        variables() {
            return variables;
        },
        routes() {
            return this.$router.options.routes;
        },
        activeMenu() {
            var path = this.$route.path;
            return "/" + path.split("/")[1];
        },
    },
};
</script>

<style lang="scss">
.mgr {
    text-align: center;
    font-size: 20px;
    vertical-align: middle;
    box-sizing: border-box;
}
.menuTool {
    margin-bottom: 10px;
}
.sideBarItem {
    height: 70px;
    line-height: 70px;
    text-align: center;
    color: #9f98ee;
    cursor: pointer;
    border-radius: 50%;
    .iconfont {
        font-size: 25px;
        border-radius: 50%;
    }
    &.activeItem {
        background: rgba(239, 238, 250, 0.1);
        .iconfont {
            color: #fff;
            padding: 8px;
            background: rgba(239, 238, 250, 0.2);
            box-shadow: none !important;
        }
    }
    &:hover {
        .iconfont {
            color: #fff;
            animation: shuibowen 0.6s linear infinite;
            background: rgba(239, 238, 250, 0.2);
        }
    }
}

@keyframes shuibowen {
    0% {
        box-shadow: 0 0 0 0 rgba(239, 238, 250, 0.12),
            0 0 0 5px rgba(239, 238, 250, 0.12),
            0 0 0 15px rgba(239, 238, 250, 0.1);
    }
    100% {
        box-shadow: 0 0 0 5px rgba(239, 238, 250, 0.12),
            0 0 0 15px rgba(239, 238, 250, 0.1),
            0 0 0 25px rgba(239, 238, 250, 0);
    }
}
</style>