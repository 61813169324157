import axios from "axios";
import store from '@/store';

var baseUrl = process.env.VUE_APP_BASE_API;
//需调用本地服务接口可取消以下注释
baseUrl = process.env.NODE_ENV == "development" ? process.env.VUE_APP_BASE_API + ":608" : process.env.VUE_APP_BASE_API;

const service = axios.create();

service.defaults.baseURL = baseUrl;
service.defaults.headers['Content-Type'] = 'application/json';

// 添加请求拦截器
service.interceptors.request.use(function (config) {
    if (store.getters.token) {
        config.headers['X-TOKEN'] = store.getters.token;
    }
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        config.transformRequest = data => {
            let ret = '';
            for (let it in data) {
                if (typeof data[it] == 'object') {
                    ret += encodeURIComponent(it) + '=' + JSON.stringify(data[it]) + '&'
                } else {
                    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
                }
            }
            ret = ret.substr(0, ret.length - 1);
            return ret;
        }
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    var data = response.data;
    var sessionId = response.headers["x-request-id"];
    //机器人测试窗口sessionId更新
    if (sessionId) {
        if (Object.keys(store.getters.sessionInfo).length > 0) {
            if (new Date().getTime() > store.getters.sessionInfo.expiresAt) {
                store.dispatch('user/setSessionInfo', sessionId);
            }
        } else {
            store.dispatch('user/setSessionInfo', sessionId);
        }
    }
    //token过期、失效
    if (data.status == -2 && data.message == "token非法!") {
        store.dispatch('user/setTokenExpired', 1);
    }
    return response.data;
}, function (error) {
    return Promise.reject(error);
});

export default service
