import request from '@/utils/mgrAxios';

//登录
export function login(data) {
    return request({
        url: "/mgr/user/login",
        method: 'post',
        data: JSON.stringify(data),
    });
}

//单点登录
export function loginByToken(data) {
    return request({
        url: "/mgr/user/loginByToken",
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//获取登录用户信息
export function getLoginUserInfo() {
    return request({
        url: "/mgr/user/getLoginUserInfo",
        method: 'get',
        data: {},
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//校验是否注册
export function checkUserExist(data) {
    return request({
        url: "/mgr/user/checkUserExist",
        method: 'post',
        data: JSON.stringify(data),
    });
}

//校验token是否合法
export function authenticate(data) {
    return request({
        url: "/mgr/auth/authenticate",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//获取图形验证码
export function getGraphCaptcha() {
    return request({
        url: "/mgr/captcha/getGraphCaptcha",
        method: 'get',
        data: {},
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//验证图形验证码
export function verifyGraphCaptcha(data) {
    return request({
        url: "/mgr/captcha/verifyGraphCaptcha",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//发送（注册）短信验证码
export function sendSmsCaptcha(data) {
    return request({
        url: "/mgr/captcha/sendSmsCaptcha",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//验证（注册）短信验证码
export function verifySmsCaptcha(data) {
    return request({
        url: "/mgr/captcha/verifySmsCaptcha",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//注册新用户
export function register(data) {
    return request({
        url: "/mgr/user/register",
        method: 'post',
        data: JSON.stringify(data),
    });
}

//发送（忘记密码）短信验证码
export function sendSmsCaptchaToUser(data) {
    return request({
        url: "/mgr/captcha/sendSmsCaptchaToUser",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//验证（忘记密码）短信验证码
export function verifySmsCaptchaForUser(data) {
    return request({
        url: "/mgr/captcha/verifySmsCaptchaForUser",
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

//忘记密码
export function forgetPassword(data) {
    return request({
        url: "/mgr/user/forgetPassword",
        method: 'post',
        data: JSON.stringify(data),
    });
}